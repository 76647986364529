import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query ClockTemplateQuery(
    $clockId: String!,
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      clock: clockById(id: $clockId) {
        ...Clock
        fragrances: fragrancesList(filter: { gender: { equalTo: $genderId }}) {
          ...Fragrance
        }
        notes: notesList(
          genderId: $genderId
          first: 5
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { clock } = data.results
  const { fragrances, notes } = clock
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = clock.name
  const parent = 'Times of Day'
  const title = `${page} - ${entity.singular} ${parent}`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: parent,
      slug: links.clocks,
    },
    {
      name: page,
      slug: clock.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: clock.about,
    image: imageMain.src,
    keywords,
    name: title,
    thing: clock,
    slug: clock.slug,
  }

  return (
    <Layout>
      <SEO
        description={clock.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Image
        alt={clock.name}
        credit={clock.image}
        fixed={imageMain}
      />

      {clock.about && (
        <Paragraph>
          {clock.about}
        </Paragraph>
      )}

      <Heading level={2}>{clock.name} {entity.plural}</Heading>
      <FragrancesList fragrances={fragrances} />

      {(notes.length > 0) && (<>
        <Heading level={2}>
          {clock.name} Notes
        </Heading>
        <List>
          {notes.map(note => (
            <ListItem key={note.id}>
              <Link href={note.slug}>
                {note.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </>)}

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
